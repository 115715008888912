import { Button, message } from "antd";
import { useEffect } from "react";

import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../../services/firebase";
import { organisers } from "../../common/config";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import log from "../../common/logger";

const Auth = () => {
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (!loading) {
      if (user !== null) {
        log("AUTH", { loading, user });
        return navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading]);

  const googleProvider = new GoogleAuthProvider();

  const handleGoogleAuth = async () => {
    try {
      const res = await signInWithPopup(auth, googleProvider);
      if (!organisers.includes(res.user.email ?? "")) {
        await auth.signOut();
        message.error("Not an admin!");
      } else {
        log("handleGoogleAuth", { loading, user });
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return (
      <div>
        <h1>Checking authentication status...</h1>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-primary-dark">
      <div className="px-[32px] py-[50px] shadow bg-primary max-w-[400px] w-full">
        <h1 className="text-white text-[18px] text-center mb-[32px]">
          Authenticate
        </h1>
        <Button
          onClick={handleGoogleAuth}
          type="primary"
          size="large"
          className="!w-full"
        >
          Sign in with Google
        </Button>
      </div>
    </div>
  );
};

export default Auth;
