import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "antd/dist/antd.css";
import "./App.css";

import Auth from "./pages/auth";
import Home from "./pages/home";
import Profile from "./pages/[id]";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/:id" element={<Profile />} />
      </Routes>
    </Router>
  );
}

export default App;
