import {
  collection,
  updateDoc,
  setDoc,
  doc,
  query,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { db } from ".";
import log from "../../common/logger";

const hackersCollectionRef = collection(db, "hackers");

export const registerHacker = (
  name: string,
  cardNo: string,
  teamName: string
) => {
  return setDoc(doc(db, "hackers", cardNo), {
    cardNo,
    name,
    teamName,
    day1Dinner: false,
    day1Lunch: false,
    day1Refreshments1: false,
    day1Refreshments2: false,
    day2Breakfast: false,
    registered: true,
    swags: false,
  });
};

export const updateHacker = (cardNo: string, data: any) => {
  return updateDoc(doc(db, "hackers", cardNo), data);
};

export const getAllHackers = async () => {
  const hackersQuery = query(hackersCollectionRef);
  const snapshot = await getDocs(hackersQuery);
  const docs: any = {};
  snapshot.forEach((doc) => {
    docs[doc.id] = doc.data();
  });
  return docs;
};

export const getHacker = async (cardNo: string) => {
  const hackerRef = doc(db, "hackers", cardNo);
  const hackerSnap = await getDoc(hackerRef);
  if (hackerSnap.exists()) {
    return hackerSnap.data();
  }
  return null;
};
