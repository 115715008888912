import { Button, Form, Input, Modal, Switch } from "antd";
import { Dispatch, SetStateAction, useEffect } from "react";
import log from "../../common/logger";
import { useHackerStore } from "../../state/Hacker";

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  cardNo: string;
}

const EditModal = ({ isOpen, setIsOpen, cardNo }: Props) => {
  const [{ selectedHacker }, { editHacker, getSelectedHacker }] =
    useHackerStore();

  useEffect(() => {
    if (cardNo) getSelectedHacker(cardNo);
  }, [cardNo]);

  const handleSubmit = (data: any) => {
    editHacker(cardNo, data);
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      onOk={() => setIsOpen(false)}
      title="Edit Hacker"
      footer={false}
      centered
    >
      {selectedHacker && (
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
            name: selectedHacker?.name,
            teamName: selectedHacker?.teamName,
            day1Lunch: selectedHacker?.day1Lunch,
            day1Refreshments1: selectedHacker?.day1Refreshments1,
            day1Dinner: selectedHacker?.day1Dinner,
            day1Refreshments2: selectedHacker?.day1Refreshments2,
            day2Breakfast: selectedHacker?.day2Breakfast,
            swags: selectedHacker?.swags,
          }}
        >
          <Form.Item label="Name" name="name">
            <Input placeholder="Enter hacker's name" required />
          </Form.Item>
          <Form.Item label="Team name" name="teamName">
            <Input placeholder="Enter hacker's team name" required />
          </Form.Item>
          <h3 className="text-[16px] font-semibold">Day 1</h3>
          <div className="grid grid-cols-3 gap-x-[20px]">
            <Form.Item label="Lunch" valuePropName="checked" name="day1Lunch">
              <Switch />
            </Form.Item>
            <Form.Item
              label="Refreshments 1"
              valuePropName="checked"
              name="day1Refreshments1"
            >
              <Switch />
            </Form.Item>
            <Form.Item label="Dinner" valuePropName="checked" name="day1Dinner">
              <Switch />
            </Form.Item>
            <Form.Item
              label="Refreshments 2"
              valuePropName="checked"
              name="day1Refreshments2"
            >
              <Switch />
            </Form.Item>
          </div>
          <h3 className="text-[16px] font-semibold">Day 2</h3>
          <div className="grid grid-cols-3 gap-x-[20px]">
            <Form.Item
              label="Breakfast"
              valuePropName="checked"
              name="day2Breakfast"
            >
              <Switch />
            </Form.Item>
            <Form.Item label="Swags" valuePropName="checked" name="swags">
              <Switch />
            </Form.Item>
          </div>
          <Button
            htmlType="submit"
            type="primary"
            className="w-full"
            size="large"
          >
            Submit
          </Button>
        </Form>
      )}
    </Modal>
  );
};

export default EditModal;
