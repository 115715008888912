import TeamHackerJSON from "../../static/team_hacker.json";

interface initialStateType {
  allHackers: any;
  teamHackers: typeof TeamHackerJSON;
  selectedHacker: any;
  hackerProfile: any;
  isFetching: boolean;
}

const initialState: initialStateType = {
  allHackers: [],
  teamHackers: TeamHackerJSON,
  selectedHacker: null,
  hackerProfile: null,
  isFetching: false,
};

export default initialState;
