import { Button } from "antd";
import { auth } from "../../services/firebase";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="flex items-center justify-between px-[32px] md:px-[64px] lg:px-[120px] py-[20px] max-w-[1440px] mx-auto w-full">
      <h2 className="text-[20px] text-white flex-[2]">Dashboard</h2>
      <ul className="flex-1 flex items-center justify-between">
        <li>
          <Link to={"/"}>Dashboard</Link>
        </li>
        <li>
          <Button
            type="primary"
            danger
            size="large"
            onClick={async () => await auth.signOut()}
          >
            Logout
          </Button>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
