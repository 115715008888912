import { Button, Form, Input, Switch } from "antd";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams } from "react-router-dom";
import log from "../../common/logger";
import Navbar from "../../components/Navbar";
import RegisterModal from "../../components/RegisterModal";
import { auth } from "../../services/firebase";
import { useHackerStore } from "../../state/Hacker";

const Profile = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [user, loading] = useAuthState(auth);
  const [{ hackerProfile }, { getHackerById, editHacker }] = useHackerStore();

  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!loading) {
      if (!user) return navigate("/auth");
      else fetchHacker();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading]);

  const fetchHacker = async () => {
    await getHackerById(params.id as string);
  };

  const handleSubmit = (data: any) => {
    log("handelSubmit", data);
    editHacker(params.id as string, data);
  };

  useEffect(() => {
    if (hackerProfile) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  }, [hackerProfile]);

  return (
    <div className="bg-primary-dark min-h-screen h-full w-full">
      <Navbar />
      <div className="mx-auto pt-[120px] max-w-[1440px] px-[32px] md:px-[64px] lg:px-[120px]">
        {hackerProfile ? (
          <Form
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={{
              name: hackerProfile?.name,
              teamName: hackerProfile?.teamName,
              day1Lunch: hackerProfile?.day1Lunch,
              day1Refreshments1: hackerProfile?.day1Refreshments1,
              day1Dinner: hackerProfile?.day1Dinner,
              day1Refreshments2: hackerProfile?.day1Refreshments2,
              day2Breakfast: hackerProfile?.day2Breakfast,
              swags: hackerProfile?.swags,
            }}
            id="form"
          >
            <Form.Item label="Name" name="name">
              <Input placeholder="Enter hacker's name" required />
            </Form.Item>
            <Form.Item label="Team name" name="teamName">
              <Input placeholder="Enter hacker's team name" required />
            </Form.Item>
            <h3 className="text-white text-[16px] font-semibold">Day 1</h3>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-x-[20px]">
              <Form.Item label="Lunch" valuePropName="checked" name="day1Lunch">
                <Switch />
              </Form.Item>
              <Form.Item
                label="Refreshments 1"
                valuePropName="checked"
                name="day1Refreshments1"
              >
                <Switch />
              </Form.Item>
              <Form.Item
                label="Dinner"
                valuePropName="checked"
                name="day1Dinner"
              >
                <Switch />
              </Form.Item>
              <Form.Item
                label="Refreshments 2"
                valuePropName="checked"
                name="day1Refreshments2"
              >
                <Switch />
              </Form.Item>
            </div>
            <h3 className="text-white text-[16px] font-semibold">Day 2</h3>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-x-[20px]">
              <Form.Item
                label="Breakfast"
                valuePropName="checked"
                name="day2Breakfast"
              >
                <Switch />
              </Form.Item>
              <Form.Item label="Swags" valuePropName="checked" name="swags">
                <Switch />
              </Form.Item>
            </div>
            <Button
              htmlType="submit"
              type="primary"
              className="w-full"
              size="large"
            >
              Submit
            </Button>
          </Form>
        ) : (
          <h3 className="text-white">Loading...</h3>
        )}
        <RegisterModal
          cardNo={params?.id as string}
          isOpen={visible}
          setIsOpen={setVisible}
        />
      </div>
    </div>
  );
};

export default Profile;
