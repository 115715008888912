import { Button, Checkbox, Table } from "antd";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import log from "../../common/logger";
import EditModal from "../../components/EditModal";
import Navbar from "../../components/Navbar";
import RegisterModal from "../../components/RegisterModal";

import { auth } from "../../services/firebase";
import { getAllHackers, registerHacker } from "../../services/firebase/hacker";
import { useHackerStore } from "../../state/Hacker";
import TeamHackersJSON from "../../static/team_hacker.json";

interface Columns {
  key: string;
  cardNo: string;
  name: string;
  registered: boolean;
  teamName: string;
  day1Lunch: boolean;
  day1Refreshments1: boolean;
  day1Dinner: boolean;
  day1Refreshments2: boolean;
  day2Breakfast: boolean;
  swags: boolean;
}

const { ColumnGroup, Column } = Table;

const nameFilters: { text: string; value: string }[] = [];
Object.values(TeamHackersJSON).forEach((val) =>
  val.forEach((v) => {
    nameFilters.push({
      text: v,
      value: v,
    });
  })
);
const teamNameFilters = Object.keys(TeamHackersJSON).map((val) => ({
  text: val,
  value: val,
}));

const Home = () => {
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isRegisterOpen, setIsRegisterOpen] = useState<boolean>(false);
  const [selectedCardNo, setSelectedCardNo] = useState<string>("1");

  const navigator = useNavigate();
  const [user, loading] = useAuthState(auth);

  const [data, setData] = useState<any>(null);

  const [{ allHackers }, { getAllHackers }] = useHackerStore();

  useEffect(() => {
    if (!loading) {
      if (!user) {
        log("Home", { user, loading });
        return navigator("/auth");
      } else getAllHackers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading]);

  useEffect(() => {
    prepareTableData(allHackers);
  }, [allHackers]);

  const prepareTableData = (registeredHackers: any) => {
    const hackersData = [];
    for (let i = 1; i < 86; i++) {
      hackersData.push(
        registeredHackers[i]
          ? registeredHackers[i]
          : {
              cardNo: i.toString(),
              registered: false,
              name: "",
              teamName: "",
            }
      );
    }
    setData(hackersData);
  };

  if (loading) {
    return (
      <div>
        <h1>Checking authentication status...</h1>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-screen bg-primary-dark">
      <Navbar />
      <div className="max-w-[1440px] w-full mx-auto px-[32px] md:px-[64px] lg:px-[120px]">
        <Table
          dataSource={data}
          style={{ background: "#161930" }}
          scroll={{
            x: 800,
          }}
          rowKey="cardNo"
        >
          <Column
            title={
              <div className="flex items-center justify-center">Card No.</div>
            }
            dataIndex="cardNo"
            key="cardNo"
            width={80}
            fixed="left"
            render={(_, { cardNo, registered }: Columns) => (
              <div className="flex items-center justify-center">{cardNo}</div>
            )}
            sorter={(a, b) => (a > b ? 1 : -1)}
          />
          <Column
            title={<div className="flex items-center justify-center">Name</div>}
            dataIndex="name"
            key="name"
            width={120}
            render={(_, { name, registered }: Columns) => (
              <div className="flex items-center justify-center">
                {registered ? name : "Not registered"}
              </div>
            )}
            sorter={(a, b) => (a > b ? 1 : -1)}
            onFilter={(value: any, record) => record.name.includes(value)}
            filters={nameFilters}
            filterSearch
            ellipsis
          />
          <Column
            title={
              <div className="flex items-center justify-center">Team Name</div>
            }
            dataIndex="teamName"
            key="teamName"
            width={120}
            render={(_, { teamName, registered }: Columns) => (
              <div className="flex items-center justify-center">
                {registered ? teamName : "Not registered"}
              </div>
            )}
            onFilter={(value: any, record) => record.teamName.includes(value)}
            filters={teamNameFilters}
            sorter={(a, b) => (a > b ? 1 : -1)}
            filterSearch
            ellipsis
          />

          <ColumnGroup
            title={<div className="flex items-center justify-center">Day1</div>}
          >
            <Column
              title={
                <div className="flex items-center justify-center">Lunch</div>
              }
              dataIndex="day1Lunch"
              key="day1Lunch"
              render={(_, { day1Lunch, registered }: Columns) =>
                registered ? (
                  <div className="flex items-center justify-center">
                    <Checkbox checked={day1Lunch} />
                  </div>
                ) : null
              }
              width={65}
            />
            <Column
              title={
                <div className="flex items-center justify-center">Ref. 1</div>
              }
              dataIndex="day1Refreshments1"
              key="day1Refreshments1"
              render={(_, { day1Refreshments1, registered }: Columns) =>
                registered ? (
                  <div className="flex items-center justify-center">
                    <Checkbox checked={day1Refreshments1} />
                  </div>
                ) : null
              }
              width={75}
            />
            <Column
              title={
                <div className="flex items-center justify-center">Dinner</div>
              }
              dataIndex="day1Dinner"
              key="day1Dinner"
              render={(_, { day1Dinner, registered }: Columns) =>
                registered ? (
                  <div className="flex items-center justify-center">
                    <Checkbox checked={day1Dinner} />
                  </div>
                ) : null
              }
              width={65}
            />
            <Column
              title={
                <div className="flex items-center justify-center">Ref. 2</div>
              }
              dataIndex="day1Refreshments2"
              key="day1Refreshments2"
              render={(_, { day1Refreshments2, registered }: Columns) =>
                registered ? (
                  <div className="flex items-center justify-center">
                    <Checkbox checked={day1Refreshments2} />
                  </div>
                ) : null
              }
              width={75}
            />
          </ColumnGroup>
          <ColumnGroup
            title={
              <div className="flex items-center justify-center">Day 2</div>
            }
          >
            <Column
              title={
                <div className="flex items-center justify-center">
                  Breakfast
                </div>
              }
              dataIndex="day2Breakfast"
              key="day2Breakfast"
              render={(_, { day2Breakfast, registered }: Columns) =>
                registered ? (
                  <div className="flex items-center justify-center">
                    <Checkbox checked={day2Breakfast} />
                  </div>
                ) : null
              }
              width={65}
            />
          </ColumnGroup>
          <Column
            title={
              <div className="flex items-center justify-center">
                Register/Edit
              </div>
            }
            dataIndex="register"
            key="register"
            render={(_, { registered, cardNo }: Columns) => (
              <div className="flex items-center justify-center">
                {registered ? (
                  <Button
                    onClick={() => {
                      setSelectedCardNo(cardNo);
                      setIsEditOpen(true);
                    }}
                    type="primary"
                    danger
                  >
                    Edit
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setSelectedCardNo(cardNo);
                      setIsRegisterOpen(true);
                    }}
                    type="primary"
                  >
                    Register
                  </Button>
                )}
              </div>
            )}
            width={120}
            fixed="right"
          />
        </Table>
      </div>
      {user && (
        <>
          <EditModal
            cardNo={selectedCardNo}
            isOpen={isEditOpen}
            setIsOpen={setIsEditOpen}
          />
          <RegisterModal
            cardNo={selectedCardNo}
            isOpen={isRegisterOpen}
            setIsOpen={setIsRegisterOpen}
          />
        </>
      )}
    </div>
  );
};

export default Home;
