export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

export const organisers = [
  "abhinavrajesh49@gmail.com",
  "ajmessial10@gmail.com",
  "abhinavtb@gmail.com",
  "aswinpradeepc@gmail.com",
  "varshashaheen2003@gmail.com",
  "anitoanto07@gmail.com",
  "pa.abdulhakkeem02@pg.cusat.ac.in",
  "sunithvs2002@gmail.com",
  "tprohit9@gmail.com",
  "ajnasnb@gmail.com",
  "amruthadineshsuttu@gmail.com"
];
