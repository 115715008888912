import ActionTypes from "../types";
import {
  getAllHackers,
  getHacker,
  registerHacker,
  updateHacker,
} from "../../services/firebase/hacker";
import log from "../../common/logger";

type Hacker = any;

const actions = {
  setAllHackers:
    (allHackers: Hacker[]) =>
    ({ setState }: ActionTypes) => {
      setState({
        allHackers,
      });
    },
  setSelectedHacker:
    (selectedHacker: Hacker) =>
    ({ setState }: ActionTypes) => {
      setState({ selectedHacker });
    },
  setHackerProfile:
    (hackerProfile: Hacker) =>
    ({ setState }: ActionTypes) => {
      setState({ hackerProfile });
    },
  setIsFetching:
    (isFetching: boolean) =>
    ({ setState }: ActionTypes) => {
      setState({ isFetching });
    },

  getAllHackers:
    () =>
    async ({ dispatch }: ActionTypes) => {
      const response = await getAllHackers();
      log("getAllHackers", response);
      dispatch(actions.setAllHackers(response));
    },
  getSelectedHacker:
    (cardNo: string) =>
    async ({ dispatch }: ActionTypes) => {
      const response = await getHacker(cardNo);
      log("getSelectedHacker", response);
      dispatch(actions.setSelectedHacker(response));
    },
  editHacker:
    (cardNo: string, data: Hacker) =>
    async ({ dispatch }: ActionTypes) => {
      await updateHacker(cardNo, data);
    },
  registerHacker:
    (cardNo: string, data: Hacker) =>
    async ({ dispatch }: ActionTypes) => {
      const response = await registerHacker(data.name, cardNo, data.teamName);
      dispatch(actions.refreshHackers());
    },
  getHackerById:
    (cardNo: string) =>
    async ({ dispatch }: ActionTypes) => {
      const response = await getHacker(cardNo);
      log("response", response);
      dispatch(actions.setHackerProfile(response));
    },
  refreshHackers:
    () =>
    ({ dispatch }: ActionTypes) => {
      log("Refresing...");
      dispatch(actions.getAllHackers());
      dispatch(actions.setSelectedHacker(null));
    },
};

export default actions;
