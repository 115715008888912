import { Button, Form, message, Modal, Select } from "antd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import log from "../../common/logger";
import { useHackerStore } from "../../state/Hacker";
import * as TeamHackersJSON from "../../static/team_hacker.json";

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  cardNo: string;
}

const { Option } = Select;

const RegisterModal = ({ isOpen, setIsOpen, cardNo }: Props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [
    { selectedHacker, isFetching },
    { registerHacker, getSelectedHacker },
  ] = useHackerStore();
  const [nameOptions, setNameOptions] = useState<string[]>([]);

  useEffect(() => {
    if (cardNo) getSelectedHacker(cardNo);
    if (!isFetching) {
      if (selectedHacker !== null && selectedHacker?.cardNo === cardNo) {
        message.error(`User ${cardNo} already registers`);
        return navigate("/");
      }
    }
  }, [cardNo]);

  const handleSubmit = (values: any) => {
    if (
      typeof values.name === "undefined" ||
      typeof values.teamName === "undefined"
    ) {
      message.error("Name and Team Name are required for registration!");
      return;
    }
    registerHacker(cardNo, {
      name: values.name,
      teamName: values.teamName,
    });
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      onOk={() => setIsOpen(false)}
      title="Checkin user"
      footer={false}
      centered
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item label="Team name" name="teamName">
          <Select
            showSearch
            placeholder="Enter hacker's team name"
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            onChange={(teamName: keyof typeof TeamHackersJSON) => {
              setNameOptions(TeamHackersJSON[teamName] as string[]);
              form.setFieldValue("name", undefined);
            }}
          >
            {Object.keys(TeamHackersJSON).map((teamName, key) => (
              <Option value={teamName} key={teamName + key}>
                {teamName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Name" name="name">
          <Select
            showSearch
            placeholder="Enter hacker's team name"
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {nameOptions.map((name, key) => (
              <Option value={name} key={name + key}>
                {name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          className="w-full"
          size="large"
        >
          Submit
        </Button>
      </Form>
    </Modal>
  );
};

export default RegisterModal;
